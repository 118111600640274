<template>
  <div id="app">
    <img alt="Vue logo" src="./assets/super5ives.png" style="max-width: 300px;">
    <Labeller :shipment="shipment_id" v-if="shipment_id" />
    <b-container v-if="!shipment_id">
        <b-alert variant="danger" show>
            No shipment ID was passed, so labels cannot be generated.  If you followed a link from an email, please make sure you've copied the entire link.
            Please contact us if you continue to have issues.
        </b-alert>
    </b-container>
  </div>
</template>

<script>
import Labeller from './components/Labeller.vue'

export default {
  name: 'App',
  components: {
    Labeller
  },
  data(){
    let uri = window.location.search.substring(1); 
    let params = new URLSearchParams(uri);

    return {
      shipment_id: parseInt(params.get("shipment_id")),
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
