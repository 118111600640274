<template>
  <div>
      <div class="overlay" v-if="working">
      </div>
      <b-container v-if="shipment_meta && canLabel && !global_error && !labels.length">
        <b-row v-if="shipment_meta.from" class="mb-1">
          <b-col cols="12" md="3">
            <label for="parcel_count">Shipping from:</label>
          </b-col>
          <b-col cols="12" md="9">
            <span>{{shipment_meta.from.company}} - {{shipment_meta.from.warehouse.name}} - {{shipment_meta.from.warehouse.postal_code}}, {{shipment_meta.from.warehouse.country}}</span>
          </b-col>
        </b-row>
        <b-row v-if="shipment_meta.to" class="mb-1">
          <b-col cols="12" md="3">
            <label for="shipping_from">Shipping to:</label>
          </b-col>
          <b-col cols="12" md="9">
            <span>{{shipment_meta.to.company}} - {{shipment_meta.to.warehouse.name}} - {{shipment_meta.to.warehouse.postal_code}}, {{shipment_meta.to.warehouse.country}}</span>
          </b-col>
        </b-row>
        <b-row v-if="isInternational">
          <b-col cols="12" md="3">
            <label for="parcel_count">Invoice number:</label>
          </b-col>
          <b-col cols="12" md="9">
            <b-form-input v-model="invoiceNumber"></b-form-input>
          </b-col>
        </b-row>
        <b-row v-if="isInternational">
          <b-col cols="12" md="3">
            <label for="parcel_count">Invoice date:</label>
          </b-col>
          <b-col cols="12" md="9">
            <b-form-datepicker v-model="invoiceDate"></b-form-datepicker>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="3">
            <label for="parcel_count">Number of parcels:</label>
          </b-col>
          <b-col cols="12" md="9">
            <b-form-input v-model="parcel_count" type="number"></b-form-input>
          </b-col>
        </b-row>
        <b-row v-for="n in parseInt(parcel_count)" v-bind:key="n" class="my-3">
          <b-col>
              <b-row class="my-1">
                  <b-col sm="3">
                    <label for="parcel_count">Package {{n}} weight:</label>
                  </b-col>
                  <b-col sm="2">
                    <b-input-group>
                      <b-form-input v-model="parcels[n-1].weight" type="number"></b-form-input>
                      <template v-slot:append>
                          <b-input-group-text>kg</b-input-group-text>
                      </template>
                    </b-input-group>
                  </b-col>
              </b-row>
              <b-row class="my-1">
                  <b-col sm="3">
                    <label for="parcel_count">Package {{n}} dimensions:</label>
                  </b-col>
                  <b-col sm="2">
                    <b-input-group>
                        <b-form-input v-model="parcels[n-1].length" type="number"></b-form-input>
                        <template v-slot:append>
                            <b-input-group-text>cm</b-input-group-text>
                        </template>
                    </b-input-group>
                  </b-col>
                  <b-col sm="2">
                    <b-input-group>
                        <b-form-input v-model="parcels[n-1].width" type="number"></b-form-input>
                        <template v-slot:append>
                            <b-input-group-text>cm</b-input-group-text>
                        </template>
                    </b-input-group>
                  </b-col>
                  <b-col sm="2">
                    <b-input-group>
                        <b-form-input v-model="parcels[n-1].height" type="number"></b-form-input>
                        <template v-slot:append>
                            <b-input-group-text>cm</b-input-group-text>
                        </template>
                    </b-input-group>
                  </b-col>
              </b-row>
           </b-col>
        </b-row>
        <b-row class="py-3" v-if="!isInternational">
            <b-col offset-md="3">
                <b-form-checkbox v-model="pickup">
                    Check this box if you <b>do not</b> have a UPS collection booked.
                </b-form-checkbox>
            </b-col>
        </b-row>
        <b-alert :show="pickup" variant="info">
            We will book a pickup from your warehouse at: 
            {{shipment_meta.from.warehouse.address_line_1}},
            <span v-if="shipment_meta.from.warehouse.address_line_2">{{shipment_meta.from.warehouse.address_line_2}}, </span>
            <span v-if="shipment_meta.from.warehouse.address_line_3">{{shipment_meta.from.warehouse.address_line_3}}, </span>
            {{shipment_meta.from.warehouse.city}},
            {{shipment_meta.from.warehouse.postal_code}},
            {{shipment_meta.from.warehouse.country}}
        </b-alert>
        <b-row v-if="readyToSubmit" class="align-items-right flex-row-reverse">
            <b-col cols="2">
                <b-button size="lg" variant="success" @click="doLabel">Print labels</b-button>
            </b-col>
        </b-row>
      </b-container>
      <b-container v-if="labels.length">
        <b-alert variant="success" show>Your labels are ready for printing (a print dialog should appear shortly).</b-alert>
        <b-alert v-if="this.pickup_ref" variant="success" show>Pickup booked for {{pickup_date}}.  Your pickup reference is {{pickup_ref}}</b-alert>
      </b-container>
      <b-container v-if="!canLabel && !global_error">
        <b-alert variant="danger" show>Please contact Super5ives as this shipment does not have enough information to use this labelling tool.</b-alert>
      </b-container>
      <b-container v-if="global_error">
        <b-alert variant="danger" show>{{global_error}}</b-alert>
      </b-container>
      <labelFrame id="labeldoc" style="display: none; width: 100%; height: 1000px;" :labels="labels">
        <div>
            <div v-for="label in labels" v-bind:key="label.gif" style="page-break-after: always; width: 95%" class="label">
                <img :src="'data:image/gif;base64,' + label.gif" />
            </div>
        </div>
      </labelFrame>
    </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'Labeller',
  props: {
    shipment: Number
  },
  data(){
    return {
        global_error: null,
        shipment_meta: {},
        parcel_count: 1,
        baseUrl: process.env.VUE_APP_API_BASE,
        parcels: [{
            weight: 0,
            packaging: null,
            length: null,
            width: null,
            height: null,
        }],
        pickup: false,
        pickup_ref: null,
        pickup_date: null,
        invoiceNumber: null,
        invoiceDate: null,
        working: false,
        labels: [],
    }
  },
  computed: {
      readyToSubmit(){
        for(var j=0; j<this.parcel_count; j++){
            if(
                (!this.parcels[j].weight) ||
                (!this.parcels[j].width) ||
                (!this.parcels[j].length) ||
                (!this.parcels[j].height)) return false;
        }
        return true;
      },
    canLabel(){
      if(
            this.shipment_meta.from &&
            this.shipment_meta.from.warehouse &&
            this.shipment_meta.from.warehouse.address_line_1 &&
            this.shipment_meta.from.warehouse.postal_code &&
            this.shipment_meta.from.warehouse.country &&
            this.shipment_meta.to &&
            this.shipment_meta.to.warehouse.address_line_1 &&
            this.shipment_meta.to.warehouse.postal_code &&
            this.shipment_meta.to.warehouse.country
        ) return true;
      return false;
    },
    isInternational(){
        return this.shipment_meta.to.warehouse.country != this.shipment_meta.from.warehouse.country
    }
  },
  mounted(){
    this.working = true;
    axios.get(this.baseUrl + "/api/super5ives/shipment-labels/" + this.shipment).then((response) => {
        this.shipment_meta = response.data.shipment;
        this.working = false;
    }).catch((error) => {
        this.working = false;
        if(error.response.data && error.response.data.error){
            this.global_error = "Error returned from the server: " + error.response.data.error;
        }else{
            this.global_error = "Unable to communicate with the server";
        }
    })
  },
  methods: {
    doLabel(){
        this.working = true;
        var post_object = {
          packages: this.parcels,
          pickup: this.pickup,
          invoice_number: this.invoiceNumber,
          invoice_date: this.invoiceDate,
        };
        var _self = this;
        axios.post(this.baseUrl + "/api/super5ives/shipment-labels/" + this.shipment, post_object).then((response) => {
            console.log(response.data.labels);
            //_self.labels = response.data.labels;
            _self.all_documents = response.data.all_documents;
            _self.pickup_date = response.data.pickup_date;
            _self.pickup_ref = response.data.pickup_ref;
            _self.working = false;
            /*window.setTimeout(() => {
                document.getElementById("labeldoc").contentWindow.print();
            }, 1000);*/

            const linkSource = "data:application/pdf;base64," + response.data.all_documents;
            const downloadLink = document.createElement("a");
            const fileName = "Super5ives-" + _self.shipment_meta.ref + "-Combined.pdf";

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();

        }).catch((response) => {
            this.working = false;
            console.error(response);
            if(response.data){
                this.global_error = "Error returned from the server: " + response.data.error;
            }else{
                this.global_error = "Unable to communicate with the server";
            }
        })
    }
  },
    watch: {
        parcel_count: function(){
            while(this.parcels.length < this.parcel_count){
                this.parcels.push({
                    weight: null,
                    packaging: null,
                    length: null,
                    width: null,
                    height: null,
                });
            }
            while(this.parcels.length > this.parcel_count){
                this.parcels.pop();
            }
        }
    }
}
</script>

<style>
@media print {
    body{
        background: #FFF;
    }

    .label {
        width: 99%;
        min-width: 99%;
        max-width: 99%;
        padding-left: 0;
        padding-right: 0;
        margin-left:0;
        margin-right:0;
    }
}

.overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(0,0,0,0.2);
}
</style>
